import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Button, Container, Icon, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

export const Hero = () => {
    const [toggleGrey, setToggleGrey] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            setToggleGrey(false);
        }, 1000);
    }, []);

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    filter: `${toggleGrey ? 'grayscale(1)' : 'grayscale(0)'} brightness(0.4)`,
                    transition: 'filter 0.25s ease-in-out',
                }}
            >
                <Carousel interval={10000} animation="slide" duration={1500} height={'95vh'} navButtonsAlwaysVisible={true}>
                    <StaticImage src="../images/example7.jpg" alt="Een geschilderd huis" placeholder="blurred" style={{ height: '95vh' }} />
                    <StaticImage src="../images/example3.jpeg" alt="Kozijn" placeholder="blurred" style={{ height: '95vh' }} />
                    <StaticImage src="../images/example5.jpg" alt="Woning" placeholder="blurred" style={{ height: '95vh' }} />
                </Carousel>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    inset: '0 16px 0 16px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    pointerEvents: 'none',
                }}
            >
                <Typography variant="h1" component="h1" color={(theme) => theme.palette.common.white}>
                    Haasnoot Coatings
                </Typography>
                <Typography variant="subtitle1" component="div" mb={5} color={(theme) => theme.palette.common.white}>
                    Voor al uw schilderwerken
                </Typography>
                <Button variant="outlined" href="#contact" sx={{ pointerEvents: 'all' }}>
                    Contact opnemen
                    <Icon component={ArrowRightAlt} sx={{ marginLeft: 1 }} />
                </Button>
            </Box>
        </Container>
    );
};
