import { CheckCircleOutlineSharp, FlareOutlined, Phone, SentimentSatisfied } from '@mui/icons-material';
import { ImageList, ImageListItem, ImageListItemBar, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { DefaultContainer } from '../components/DefaultContainer';
import { Hero } from '../components/Hero';
import { Layout } from '../components/Layout';
import { TextMedia } from '../components/TextMedia';
import { Usp } from '../components/Usp';
import '../css/app.css';
import busImage from '../images/bus2.jpg';

export const index = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true,
    });

    return (
        <>
            <Layout>
                <Hero />

                <DefaultContainer maxWidth="lg">
                    <TextMedia
                        title="Het bedrijf"
                        headline={{text: "Opgericht op 1 januari 2022"}}
                        text="<i>Na 18 jaar ervaring in binnen en buitenschilderwerk bij meerdere
                        bedrijven was het tijd voor een nieuwe uitdaging. </i>
                        <br></br> Het concept van Haasnoot Coatings is simpel, <b>degelijk schilderwerk voor een betaalbare prijs.</b> Als
                        eenmanszaak komt u niet voor verrassingen te staan. Ik neem het werk op en voer het ook uit. Haasnoot Coatings wil uw zorg
                        voor onderhoud weg nemen en een vertrouwd gezicht blijven."
                        image={{src: busImage, alt: 'bus'}}
                        cta={{text: 'Gelijk contact?', href: 'tel:0614939766', icon: Phone }}
                        id={"bedrijf"}
                    />

                </DefaultContainer>
                <Container maxWidth={false} disableGutters sx={{ background: '#1981b514' }}>
                    <Container maxWidth="lg" sx={{ pt: isMobile ? 10 : 20, pb: isMobile ? 5 : 10, mt: isMobile ? -5 : -10 }}>
                        <Typography
                            component="h2"
                            variant="h2"
                            py={isMobile ? 2.5 : 5}
                            sx={{ textAlign: 'center', color: 'black', fontWeight: '600' }}
                            id="service"
                        >
                            Wat wij te bieden hebben
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2.5, flex: '1 1 0', flexWrap: 'wrap' }}>
                            <Usp
                                title="Persoonlijke service"
                                body="Eenmanszaak in <b>direct contact</b> met de klant. Voert schilderwerk uit <b>tot het eind</b> en zal alles naar alle tevredenheid afwerken."
                                icon={SentimentSatisfied}
                            >
                                <StaticImage
                                    src="../images/bus1.jpg"
                                    alt="Staircase"
                                    placeholder="blurred"
                                    loading="lazy"
                                    imgStyle={{ objectFit: 'cover' }}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Usp>
                            <Usp
                                title="Ervaring"
                                body="Al meer dan 18 jaar <b>ervaring</b> in binnen en buitenschilderwerk."
                                icon={CheckCircleOutlineSharp}
                            >
                                <StaticImage
                                    src="../images/kleurenwaaier.jpg"
                                    alt="Staircase"
                                    placeholder="blurred"
                                    loading="lazy"
                                    imgStyle={{ objectFit: 'cover' }}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Usp>
                            <Usp
                                title="Advies"
                                body="Kijkt graag mee met de klant wat er mogelijk is en <b>adviseert</b> graag als hier behoefte aan is."
                                icon={FlareOutlined}
                            >
                                <StaticImage
                                    src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                    alt="Staircase"
                                    placeholder="blurred"
                                    loading="lazy"
                                    imgStyle={{ objectFit: 'cover' }}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Usp>
                        </Box>
                    </Container>
                </Container>

                <Container maxWidth="lg" sx={{ py: isMobile ? 10 : 20 }}>
                    <Typography component="h2" variant="h2" id="werkzaamheden" sx={{ lineHeight: 1, mb: isMobile ? 2 : 1 }}>
                        Greep uit onze{' '}
                        <Box component="span" color={(theme) => theme.palette.secondary.main}>
                            werkzaamheden
                        </Box>
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Binnen en buiten schilderwerken
                    </Typography>
                    <ImageList sx={{ width: '100%', py: isMobile ? 2.5 : 5, m: 0 }} cols={isMobile ? 1 : 3} variant="masonry" gap={isMobile ? 8 : 16}>
                        <ImageListItem>
                            <StaticImage src="../images/example1.jpg" alt="Geschilderd huis" placeholder="blurred" loading="lazy" />
                            <ImageListItemBar title={'Buitenschilderwerk Katwijk aan Zee'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/example3.jpeg" alt="Facade" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'Buitenschilderwerk Noordwijk'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/example7.jpg" alt="Overkapping" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'Overkapping Boskoop'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/example2.jpg" alt="Trappengat" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'Trappengat woning Noordwijk'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/example5.jpg" alt="VVE Waddinxeen" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'VVE Waddinxveen'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/example6.jpg" alt="Trap binnenschilderwerk" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'Binnenschilderwerk Boskoop'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/vve2.jpg" alt="VVE2" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'VVE Alphen aan de Rijn (2)'} />
                        </ImageListItem>
                        <ImageListItem>
                            <StaticImage src="../images/vve1.jpg" alt="VVE1" placeholder="blurred" loading="lazy" />;
                            <ImageListItemBar title={'VVE Alphen aan de Rijn'} />
                        </ImageListItem>
                    </ImageList>
                </Container>
            </Layout>
        </>
    );
};

export default index;
